import { default as _indexzZ9kAF8ZZXMeta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20241223151819/pages/_index.vue?macro=true";
import { default as _91_46_46_46cms_93i9pYjc9J8qMeta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20241223151819/pages/[...cms].vue?macro=true";
import { default as indexGZJwJe97PlMeta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20241223151819/pages/cart/index.vue?macro=true";
import { default as indexwjnF3fb6JTMeta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20241223151819/pages/cart/success/index.vue?macro=true";
import { default as _91_46_46_46slug_93fZbj2Sl1fIMeta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20241223151819/pages/catalog/[...slug].vue?macro=true";
import { default as indexKLkgqWPNFnMeta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20241223151819/pages/catalog/index.vue?macro=true";
import { default as _91_46_46_46slug_93HwwaG3l5MeMeta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20241223151819/pages/collection/[...slug].vue?macro=true";
import { default as index16kPwrPBuoMeta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20241223151819/pages/email/index.vue?macro=true";
import { default as indexgXpFUwg8vvMeta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20241223151819/pages/kontakty/index.vue?macro=true";
import { default as _91_46_46_46slug_93saIYziNiLEMeta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20241223151819/pages/product/[...slug].vue?macro=true";
import { default as indexGtGMn7KKHHMeta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20241223151819/pages/search/index.vue?macro=true";
import { default as indexxq6J09zLiaMeta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20241223151819/pages/wishlist/index.vue?macro=true";
export default [
  {
    name: _indexzZ9kAF8ZZXMeta?.name ?? "_index",
    path: _indexzZ9kAF8ZZXMeta?.path ?? "/_index",
    meta: _indexzZ9kAF8ZZXMeta || {},
    alias: _indexzZ9kAF8ZZXMeta?.alias || [],
    redirect: _indexzZ9kAF8ZZXMeta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20241223151819/pages/_index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46cms_93i9pYjc9J8qMeta?.name ?? "cms",
    path: _91_46_46_46cms_93i9pYjc9J8qMeta?.path ?? "/:cms(.*)*",
    meta: _91_46_46_46cms_93i9pYjc9J8qMeta || {},
    alias: _91_46_46_46cms_93i9pYjc9J8qMeta?.alias || [],
    redirect: _91_46_46_46cms_93i9pYjc9J8qMeta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20241223151819/pages/[...cms].vue").then(m => m.default || m)
  },
  {
    name: indexGZJwJe97PlMeta?.name ?? "cart",
    path: indexGZJwJe97PlMeta?.path ?? "/cart",
    meta: indexGZJwJe97PlMeta || {},
    alias: indexGZJwJe97PlMeta?.alias || [],
    redirect: indexGZJwJe97PlMeta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20241223151819/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexwjnF3fb6JTMeta?.name ?? "cart-success",
    path: indexwjnF3fb6JTMeta?.path ?? "/cart/success",
    meta: indexwjnF3fb6JTMeta || {},
    alias: indexwjnF3fb6JTMeta?.alias || [],
    redirect: indexwjnF3fb6JTMeta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20241223151819/pages/cart/success/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93fZbj2Sl1fIMeta?.name ?? "catalog-slug",
    path: _91_46_46_46slug_93fZbj2Sl1fIMeta?.path ?? "/catalog/:slug(.*)*",
    meta: _91_46_46_46slug_93fZbj2Sl1fIMeta || {},
    alias: _91_46_46_46slug_93fZbj2Sl1fIMeta?.alias || [],
    redirect: _91_46_46_46slug_93fZbj2Sl1fIMeta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20241223151819/pages/catalog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexKLkgqWPNFnMeta?.name ?? "catalog",
    path: indexKLkgqWPNFnMeta?.path ?? "/catalog",
    meta: indexKLkgqWPNFnMeta || {},
    alias: indexKLkgqWPNFnMeta?.alias || [],
    redirect: indexKLkgqWPNFnMeta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20241223151819/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93HwwaG3l5MeMeta?.name ?? "collection-slug",
    path: _91_46_46_46slug_93HwwaG3l5MeMeta?.path ?? "/collection/:slug(.*)*",
    meta: _91_46_46_46slug_93HwwaG3l5MeMeta || {},
    alias: _91_46_46_46slug_93HwwaG3l5MeMeta?.alias || [],
    redirect: _91_46_46_46slug_93HwwaG3l5MeMeta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20241223151819/pages/collection/[...slug].vue").then(m => m.default || m)
  },
  {
    name: index16kPwrPBuoMeta?.name ?? "email",
    path: index16kPwrPBuoMeta?.path ?? "/email",
    meta: index16kPwrPBuoMeta || {},
    alias: index16kPwrPBuoMeta?.alias || [],
    redirect: index16kPwrPBuoMeta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20241223151819/pages/email/index.vue").then(m => m.default || m)
  },
  {
    name: indexgXpFUwg8vvMeta?.name ?? "kontakty",
    path: indexgXpFUwg8vvMeta?.path ?? "/kontakty",
    meta: indexgXpFUwg8vvMeta || {},
    alias: indexgXpFUwg8vvMeta?.alias || [],
    redirect: indexgXpFUwg8vvMeta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20241223151819/pages/kontakty/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93saIYziNiLEMeta?.name ?? "product-slug",
    path: _91_46_46_46slug_93saIYziNiLEMeta?.path ?? "/product/:slug(.*)*",
    meta: _91_46_46_46slug_93saIYziNiLEMeta || {},
    alias: _91_46_46_46slug_93saIYziNiLEMeta?.alias || [],
    redirect: _91_46_46_46slug_93saIYziNiLEMeta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20241223151819/pages/product/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexGtGMn7KKHHMeta?.name ?? "search",
    path: indexGtGMn7KKHHMeta?.path ?? "/search",
    meta: indexGtGMn7KKHHMeta || {},
    alias: indexGtGMn7KKHHMeta?.alias || [],
    redirect: indexGtGMn7KKHHMeta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20241223151819/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexxq6J09zLiaMeta?.name ?? "wishlist",
    path: indexxq6J09zLiaMeta?.path ?? "/wishlist",
    meta: indexxq6J09zLiaMeta || {},
    alias: indexxq6J09zLiaMeta?.alias || [],
    redirect: indexxq6J09zLiaMeta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20241223151819/pages/wishlist/index.vue").then(m => m.default || m)
  }
]